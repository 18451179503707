






























































































































import { Component, Vue } from 'vue-property-decorator';
import Privacy from '@/components/consents/Privacy.vue';
import Terms from '@/components/consents/Terms.vue';
import TermsOp from '@/components/consents/TermsOp.vue';

@Component({
  components: {
    Privacy,
    Terms,
    TermsOp
  }
})
export default class P0146 extends Vue {
  termOfUseChecked = false;
  privacyChecked = false;
  opcardTermOfUseChecked = false;

  state = '';
  created() {
    this.initialize();
  }

  get userAgreed() {
    return (
      this.opcardTermOfUseChecked &&
      this.privacyChecked &&
      this.termOfUseChecked
    );
  }
  initialize() {
    // stateが入っていない(= Auth0のRuleが遷移元でない)場合はトップページに戻す
    if (typeof this.$route.query['state'] !== 'string') {
      this.$router.push('/');
      return;
    }

    this.state = this.$route.query['state'] as string;
  }

  backToRules(userAgreed: boolean) {
    const url = new URL(`https://${process.env.VUE_APP_AUTH0_DOMAIN}/continue`);
    url.searchParams.set('state', this.state);

    if (!userAgreed) {
      const logoutConfirm = window.confirm(
        '同意いただけない場合は、サービスを継続利用いただけません'
      );
      if (logoutConfirm) {
        location.href = url.toString();
      }

      return;
    }

    if (this.privacyChecked) {
      url.searchParams.set('privacy_statement_v2', 'yes');
    }
    if (this.opcardTermOfUseChecked) {
      url.searchParams.set('op_card_terms_v2', 'yes');
    }
    if (this.termOfUseChecked) {
      url.searchParams.set('one_membership_terms_v2', 'yes');
    }

    window.location.href = url.toString();
  }
}
